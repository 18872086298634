import 'react-toastify/dist/ReactToastify.min.css';

import { Box } from '@mui/material';
import { Outlet, useLoaderData, useNavigation, useRouteLoaderData } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';

import ConsoleLogger from './components/Logger/ConsoleLogger';
import { isDevelop } from './helpers/global.state';
import { Merchant_interface } from './interfaces/merchant.interfaces';
import Loader from './pages/Process/Loader';

const defaultImage = 'https://simplefi.s3.us-east-2.amazonaws.com/background_default.png';

function App() {
  const navigation = useNavigation();
  const develop = isDevelop();
  const merchantCredentials = useLoaderData() as Merchant_interface;

  if(navigation.state === 'loading'){
    return(
      <Loader/>
    );
  }

  return (
    <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>

      <Box flex={1} display={{xs: 'none', md: 'flex'}} justifyContent="center" alignItems="center">
        <img src={merchantCredentials.image_url ?? defaultImage} alt="Image" style={{ width: '100%', height: '100dvh', objectFit: 'cover' }} />
      </Box>

      <Box flex={1}>
        <Outlet/>
      </Box>

      {/* {
        develop && (
          <ConsoleLogger/>
        )
      } */}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        limit={2}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
        transition={Bounce}
        style={{marginBottom: '18px', padding: '0 15px', fontWeight: '500'}}
      />
    </Box>
  );
}

export default App;
