export default {
  translation: {
    'Moneda': 'Payment Method',
    'Seleccioná una moneda': 'Select a payment method',
    'Seleccionar moneda': 'Select payment method',
    'Red': 'Network',
    'Seleccioná una red': 'Select a network',
    'Seleccionar red': 'Select network',
    '% extra charge': '% Additional Charge',
    'Wallet': 'Wallet',
    'Seleccioná una wallet': 'Select a wallet',
    'Seleccionar wallet': 'Select wallet',
    'disclaimers': {
      'ripio': '* Maximum refund of 20,000 pesos per month per Ripio user.'
    },
    'Conectado a': 'Connected to',
    'Desconectar': 'Disconnect',
    'Conversión': 'Conversion',
    'Monto': 'Amount',
    'El email ingresado no es válido.': 'The entered email is not valid.',
    'Ir a pagar': 'Go to pay',
    'Conectar Wallet': 'Connect Wallet',
    'Pagar': 'Pay',
    'Wallet default de tu dispositivo': 'Default wallet of your device',
    'Transferí desde una wallet': 'Pay Manually',
    'Enviando...': 'Sending...',
    'Aguardando confirmación del pago.': 'Awaiting payment confirmation.',
    'Este proceso puede tardar unos segundos.': 'This process may take a few seconds.',
    'Cancelar': 'Cancel',
    'Pagar con Crypto': 'Pay with Crypto',
    'Continuar': 'Continue',
    'Pago expirado': 'Payment expired',
    'Nuevo pago': 'New payment',
    '¿Tenés algún problema?': 'Do you have any issues?',
    '¿No ves el pago aprobado? Compartinos el hash de la transacción.': 'Don’t see the payment approved? Share the transaction hash with us.',
    'Chateá con nosotros.': 'Chat with us.',
    'Envianos tu consulta': 'Send us your query',
    'Pagaste': 'You paid',
    'El pago fue realizado con éxito': 'The payment was successfully completed',
    'a': 'to',
    'Compartí tu experiencia en': 'Share your experience on',
    'El monto ingresado no es válido.': 'The entered amount is not valid.',
    'Enviá el monto exacto a la dirección indicada aquí abajo.': 'Send the exact amount to the address below.',
    'copiado al portapapeles': 'copied to clipboard',
    'Products': 'Products',
    'cart': {
      'add': 'Add to cart',
      'drawer': {
        'title': 'Cart'
      }
    },
    'static': {
      'order_not_found': {
        'title': 'The order was not created.',
        'subtitle': 'Let the cashier know you are ready to pay.',
        'button': 'Done'
      }
    },
    'actions': {
      'Cerrar': 'Close'
    },
    'process':{
      'payment':{
        'steps':{
          '1': '1. Scan the QR code from your cell phone camera.',
          '2': '2. Confirm the transaction from your wallet.',
          '3': '3. Please return to this screen to verify payment confirmation.'
        }
      }
    }
  }
};